class Sequence {

  constructor(sequenceContainer) {
    if (sequenceContainer) {
      window.setInterval(() => {
        const visibleElement = sequenceContainer.getElementsByClassName('visible')[0]
        const nextSibling = visibleElement.nextElementSibling

        if (visibleElement) {
          visibleElement.classList.remove('visible')
        }
        if (nextSibling) {
          nextSibling.classList.add('visible')
        } else {
          sequenceContainer.getElementsByTagName('div')[0].classList.add('visible')
        }
      }, 3000)
    }
  }
}

export { Sequence as default }
