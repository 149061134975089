class ToTopButton {
  static bootstrap () {
    const toTopButton = document.getElementsByClassName('js_to-top-button')[0]
    toTopButton.addEventListener('click', () => {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    })

    window.onscroll = function(){
      const top = window.pageYOffset || document.documentElement.scrollTop;
      if (top > 100) {
        toTopButton.classList.add('visible')
      } else {
        toTopButton.classList.remove('visible')
      }
    }
  }
}

export {ToTopButton as default}
