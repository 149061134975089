import MicromerceChat from '../lib/chat.min'

class Chat {
  static bootstrap (className) {
    MicromerceChat.init({
      container: `.${className}`,
      applicationId: 'a9ed2c14-f098-4d31-a48e-1c408ee9f8e3',
      baseUrl: 'https://chat.micromerce.com',
      chatName: 'Projekt Chat',
      showOnStartup: true
    })
  }
}

export {Chat as default}
