class Carousel {
  constructor (carouselItem) {
    this.scrollIndex = 0
    // buttons
    const navigation = carouselItem.querySelector('.c-carousel__navigation')
    if (navigation) {
      this.addButtons(carouselItem, navigation)
    }

    // next / prev button
    this.addClickListenerToNextAndPrevButton(carouselItem)

    let interval = this.getInterval(carouselItem)

    // play pause
    const pauseButton = carouselItem.parentNode.querySelector('.icon-pause')
    const playButton = carouselItem.parentNode.querySelector('.icon-play')
    if (playButton && pauseButton) {
      pauseButton.addEventListener('click', () => {
        clearInterval(interval)
        pauseButton.classList.add('hidden')
        playButton.classList.remove('hidden')
      })
      playButton.addEventListener('click', () => {
        interval = this.getInterval(carouselItem)
        playButton.classList.add('hidden')
        pauseButton.classList.remove('hidden')
      })
    }
  }

  addButtons (carouselItem, navigationNode) {
    const items = carouselItem.querySelectorAll('.c-carousel__item')
    for (let i = 0; i < items.length; i++) {
      const button = document.createElement('BUTTON')
      if (i === 0) {
        button.classList.add('highlight')
      }
      button.addEventListener('click', () => {
        this.scrollIndex = i
        this.scrollTo(carouselItem)
      })
      navigationNode.appendChild(button)
    }
  }

  addClickListenerToNextAndPrevButton (carouselItem) {
    const buttons = carouselItem.parentNode.getElementsByClassName('c-carousel__button')

    buttons[0].addEventListener('click', () => {
      this.scrollIndex -= 1
      this.scrollTo(carouselItem)
    })

    buttons[1].addEventListener('click', () => {
      this.scrollIndex += 1
      this.scrollTo(carouselItem)
    })
  }

  getInterval (carouselItem) {
    return window.setInterval(() => {
      this.scrollIndex++
      this.scrollTo(carouselItem)
    }, 10000)
  }


  scrollTo (carouselItem) {
    if ((carouselItem.offsetWidth * this.scrollIndex) >= carouselItem.scrollWidth) {
      this.scrollIndex = 0
    }
    if (this.scrollIndex < 0) {
      this.scrollIndex = carouselItem.scrollWidth / carouselItem.offsetWidth - 1
    }
    const buttons = carouselItem.parentNode.querySelectorAll('.c-carousel__navigation button')
    if (buttons.length) {
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove('highlight')
      }
      buttons[this.scrollIndex].classList.add('highlight')
    }


    carouselItem.scroll({
      top: 0,
      left: carouselItem.offsetWidth * this.scrollIndex,
      behavior: 'smooth'
    })
  }
}

export { Carousel as default }
