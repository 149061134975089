class CookieBanner {

  static bootstrap() {
    const banner = document.getElementsByClassName('js_cookie-banner')[0];
    if (!this.getCookie('accept-privacy')) {
      banner.classList.add('c-cookie-banner--visible')
    }
    document.body.addEventListener('click', (event) => {
      if (event.target.classList.contains('js_cookie-accept')) {
        const CookieDate = new Date;
        CookieDate.setFullYear(CookieDate.getFullYear() +1);
        document.cookie = 'accept-privacy=true; expires=' + CookieDate.toGMTString() + ';'
        banner.classList.remove('c-cookie-banner--visible')
      }
    })
  }

  static getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
}

export { CookieBanner as default }
