class Navigation {
  static bootstrap (buttonClassName, toggleClassName) {
    const navButton = document.getElementsByClassName(buttonClassName)[0]
    const nav = document.getElementsByClassName('js_nav')[0]
    navButton.addEventListener('click', () => {
      document.getElementsByTagName('body')[0].classList.toggle(toggleClassName)
    })
  }
}

export {Navigation as default}
