const activeClassName = 'c-ticker__link--visible'

class Navigation {

  static init() {
    let activeNewsIndex = 0
    const news = document.querySelectorAll('.js_newsticker a')
    const firstNews = news[0]
    let nextNews
    firstNews.classList.add(activeClassName)
    window.setInterval(() => {
      news[activeNewsIndex].classList.remove(activeClassName)
      activeNewsIndex++
      if (activeNewsIndex >= news.length) {
        activeNewsIndex = 0
      }
      nextNews = news[activeNewsIndex]
      nextNews.classList.add(activeClassName)
    }, 8000)
  }

  static bootstrap () {
    micromerce.Snippets.on('load', (snippetId) => {
      if(snippetId === 'fcdcb9e2-3254-4ca1-90b4-b7340dc76df9') { Navigation.init() }
    })
  }
}

export { Navigation as default }
