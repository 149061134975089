import Navigation from './modules/Navitation'
import Chat from './modules/Chat'

import Newsticker from './modules/Newsticker'
import CookieBanner from './modules/CookieBanner'
import Carousel from './modules/Carousel'
import Sequence from './modules/Sequence'
import ToTopButton from './modules/ToTopButton'
import smoothscroll from 'smoothscroll-polyfill'
import scrollToElement from 'scroll-to-element'

smoothscroll.polyfill()

function bootstrap () {
  CookieBanner.bootstrap()
  Navigation.bootstrap('js_burger', 'c-navigation--visible')
  Navigation.bootstrap('js_info-nav', 'c-info-nav--visible')
  Chat.bootstrap('js_chat_content')
  Newsticker.bootstrap()
  ToTopButton.bootstrap()
  micromerce.Snippets.on('load', (snippetId, url) => {
    // @TODO sollte geändert werden, wenn Snippet complete loaded event vorhanden
    if(snippetId === 'bb87ef92-cd2b-4f88-9a3f-4f8add1ada90' || snippetId === '69796307-7777-400c-aa03-82c3e8a2b8e5') {
      const carousels = document.querySelectorAll('.js_carousel')
      for (let i = 0; i < carousels.length; i++) {
        new Carousel(carousels[i])
      }
    }
    // @TODO sollte geändert werden, wenn Snippet complete loaded event vorhanden
    if(snippetId === 'f97b8896-0a5c-4782-9402-e8fcc51d8160' && document.location.hash) {
      setTimeout(() => {
        const anchorId = document.location.hash.replace('#', '')
        const element = document.querySelector(`[data-anchor-id="${anchorId}"]`)
        if (element) {
          scrollToElement(element, {
            offset: -100,
            ease: 'linear',
            duration: 100
          })
        }
      }, 100)
    }
  })
  const sequences = document.getElementsByClassName('js_sequence')
  for (let i = 0; i < sequences.length; i++) {
    new Sequence(sequences[i]);
  }
  const customerCommentsCarousel = document.querySelector('.c-carousel--kundenstimmen .js_carousel')
  if (customerCommentsCarousel) {
    new Carousel(customerCommentsCarousel);
  }
}

document.addEventListener('DOMContentLoaded', function () {
  bootstrap()
})
